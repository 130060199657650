export const ERRORS_ADD = 'app/ERRORS_ADD';
export const ERRORS_CLEAR = 'app/ERRORS_CLEAR';

export const TOASTS_ADD = 'app/TOASTS_ADD';
export const TOASTS_REMOVE = 'app/TOASTS_REMOVE';

export const SOUNDS_ADD = 'app/SOUNDS_ADD';
export const SOUNDS_REMOVE = 'app/SOUNDS_REMOVE';

export const ENABLE_SOUND = 'app/ENABLE_SOUND';
export const DISABLE_SOUND = 'app/DISABLE_SOUND';

export const ENABLE_DARK_MODE = 'app/ENABLE_DARK_MODE';
export const DISABLE_DARK_MODE = 'app/DISABLE_DARK_MODE';

export const ENABLE_REFRESH_CONTACTS = 'app/ENABLE_REFRESH';
export const DISABLE_REFRESH_CONTACTS = 'app/DISABLE_REFRESH';

export const WIPE = 'app/WIPE';

export const CHANGE_STATE = 'app/CHANGE_STATE';
export const CHANGE_USERSMENU_STATE = 'app/CHANGE_CONTACTS_STATE';

export const SET_ACCESS_TOKEN = 'app/SET_ACCESS_TOKEN';

export const INIT_USER = 'app/INIT_USER';

