import {
  FETCH_SUCCESS, FETCH_FAILURE, ACCEPT_CONTACT_SUCCESS, ACCEPT_CONTACT_FAILURE, ADD_CONTACT, ADD_MESSAGE, ADD_MESSAGE_SUCCESS, SEND_MESSAGE_SUCCESS, SEND_MESSAGE_FAILURE,
  INCOMING_MESSAGE_SUCCESS, INCOMING_MESSAGE_FAILURE, ALL_MESSAGE_READ_SUCCESS, ALL_MESSAGE_READ_FAILURE, CONTACT_DIPOSITION_SUCCESS, CONTACT_DIPOSITION_FAILURE,
  UPDATE_REQUEST,UPDATE_SUCCESS,UPDATE_FAILURE, FETCH_MESSAGES_SUCCESS, FETCH_MESSAGES_FAILURE,FETCH_CONTACTS_REQUEST,FETCH_CONTACTS_SUCCESS,
  FETCH_CONTACTS_FAILURE,FETCH_MESSAGES_REQUEST, SEND_MESSAGE_REQUEST,EMPTY_CONTACTS,CHECK_UNREAD,
  FETCH_CONTACTS_FILTERED_SUCCESS, FETCH_CONTACTS_FILTERED_FAILURE, FETCH_CONTACTS_FILTERED_REQUEST, UPDATE_CONTACT_DATOS,
  FETCH_MESSAGES_FILTERED_SUCCESS, FETCH_MESSAGES_FILTERED_FAILURE, FETCH_MESSAGES_FILTERED_REQUEST, CHANGE_MESSAGE_STATUS, SET_MESSAGE_READ, SET_MESSAGE_DELIVERED,SET_MESSAGE_REJECTED, REMOVE_CONTACT, FETCH_CLIENT_DATA_REQUEST, FETCH_CLIENT_DATA_SUCCESS, FETCH_CLIENT_DATA_FAILURE, SET_MESSAGE_REACTED, INSERT_MESSAGEREFERENCE

} from './constants';


// import axios from 'axios';
import Api from '../Api';
import moment from 'moment';  

import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as authSelectors from '../../state/auth/selectors';
import * as appActions from '../../state/app/actions';

export const addContact = (contact) => (dispatch) => {
 
  dispatch({
    type: ADD_CONTACT,
    payload: contact
  })
  dispatch(appActions.addSound('addContact'))
  dispatch({ type : CHECK_UNREAD })
}

export const contactTransfered = (contact) => (dispatch) => {

  dispatch({ type: REMOVE_CONTACT, payload: contact })

}

export const removeContact = (contact) => (dispatch) => {
  dispatch({ type: CONTACT_DIPOSITION_SUCCESS, payload: contact })
  dispatch({ type : CHECK_UNREAD })
}

export const updateContactDatos = (datos) => async (dispatch) => {
  dispatch({ type: UPDATE_CONTACT_DATOS , payload : datos })
}

export const updateContact = (contact,userName,update,success,errorFn) => async (dispatch) => {
  dispatch({ type: UPDATE_REQUEST })
  try {
    const { data } = await Api.req.put(`/prospectos/agent/${userName}/prospecto/${contact.id}/disposition`,
    { 
      campaignid: contact.campaignid,
      currentStatus : contact.status,
      status : update.status,
      agentRating : update.agentRating,
      mood : update.mood,
      willBuy : update.willBuy,
      transferAgent : update.transferAgent,
      message : update.message
    });
    dispatch({ type: UPDATE_SUCCESS, payload: data.data[0] })
    success(data.data[0])
  }
  catch (err) {
    console.log(err);
    let error = err.message;
    if (err.response) {
      error = err.response.data.error;
    } else if (err.request) {
      error = err.request;
    }
    dispatch({ type: UPDATE_FAILURE, error: (error || err) });
    errorFn()
  }
}

export const acceptContact = (contact, userName,success) => async (dispatch) => {
  try {
    const { data } = await Api.req.put(`/prospectos/agent/${userName}/prospecto/${contact.id}`, { status: 1 });

    dispatch({ type: ACCEPT_CONTACT_SUCCESS, payload: data.data[0] })
    dispatch({ type : CHECK_UNREAD })
  }
  catch (err) {
    console.log(err);
    let error = err.message;
    if (err.response) {
      error = err.response.data.error;
    } else if (err.request) {
      error = err.request;
    }
    dispatch({ type: ACCEPT_CONTACT_FAILURE, error: (error || err) });
    success();
  }
}

export const fetchContactsFiltered = (fechaDesde,fechaHasta,campaign,statusFetch, agent, callerid) => async (dispatch) => {
  dispatch({ type: FETCH_CONTACTS_REQUEST })
  let fechaD = moment(fechaDesde).utcOffset(0, true).format("YYYY-MM-DD");
  let fechaH = moment(fechaHasta).utcOffset(0, true).format("YYYY-MM-DD");
  let addAgent = agent ? `&agentid=${agent}` : '';
  let addCallerId = callerid ? `&callerid=${callerid}` : '';
  let status = statusFetch ? statusFetch : 0;

  try {
    const { data } = await Api.req.get(`campaigns/${campaign}/status/${status}/contacts?startDate=${fechaD}&endDate=${fechaH}` + addAgent + addCallerId);
    const parsedData = await data.data;
    parsedData.sort((a,b) => a.lastInboundMsgTime > b.lastInboundMsgTime) 
    if(data.total == 0){
      dispatch(appActions.addToast('No han habido resultados','warning'))
    }

    if(parsedData){
      dispatch({ type: FETCH_CONTACTS_SUCCESS, payload: parsedData })
    }

  }
  catch (err) {
    let error = err.message;
    if (err.response) {
      error = err.response.data.error;
    } else if (err.request) {
      error = err.request;
    }
    dispatch({ type: FETCH_CONTACTS_FAILURE, error: (error || err) });
  }
}

export const fetchContactsSupervisorInbox = (agent) => async (dispatch) => {
  dispatch({ type: FETCH_CONTACTS_REQUEST })

  try {
    const { data } = await Api.req.get(`/supervisor/inbox/${agent}/prospectos`);
    const parsedData = await data.data;
    parsedData.sort((a, b) => a.lastInboundMsgTime > b.lastInboundMsgTime)
    if (data.total == 0) {
      dispatch(appActions.addToast('No han habido resultados', 'warning'))
    }

    if (parsedData) {

      dispatch({ type: FETCH_CONTACTS_SUCCESS, payload: parsedData })
    }

  }
  catch (err) {
    let error = err.message;
    if (err.response) {
      error = err.response.data.error;
    } else if (err.request) {
      error = err.request;
    }
    dispatch({ type: FETCH_CONTACTS_FAILURE, error: (error || err) });
  }
}

export const fetchContactsSupervisorFiltered = (fechaDesde,fechaHasta,campaign,status) => async (dispatch) => {
  dispatch({ type: FETCH_CONTACTS_REQUEST })
  let fechaD = moment(fechaDesde).utcOffset(0, true).format("YYYY-MM-DD");
  let fechaH = moment(fechaHasta).utcOffset(0, true).format("YYYY-MM-DD");
  try {
    const { data } = await Api.req.get(`campaigns/${campaign}/status/${status}/contacts?startDate=${fechaD}&endDate=${fechaH}`);
    const parsedData = await data.data;
    parsedData.sort((a,b) => a.lastInboundMsgTime > b.lastInboundMsgTime) 
    if(data.total == 0){
      dispatch(appActions.addToast('No han habido resultados','warning'))
    }

    if(parsedData){
      dispatch({ type: FETCH_CONTACTS_FILTERED_SUCCESS, payload: parsedData })
    }

  }
  catch (err) {
    let error = err.message;
    if (err.response) {
      error = err.response.data.error;
    } else if (err.request) {
      error = err.request;
    }
    dispatch({ type: FETCH_CONTACTS_FAILURE, error: (error || err) });
  }
}

export const fetchContactsMercadoLibre = (userName) => async (dispatch) => {
  dispatch({ type: FETCH_CONTACTS_REQUEST })
  try {
    const { data } = await Api.req.get(`https://omni.tevecompras.com/meli/questions`);
    const parsedData = await data.data;


    if (parsedData) {
      dispatch({ type: FETCH_CONTACTS_SUCCESS, payload: parsedData })
      dispatch({ type: CHECK_UNREAD })
    }

  }
  catch (err) {
    let error = err.message;
    if (err.response) {
      error = err.response.data.error;
    } else if (err.request) {
      error = err.request;
    }
    dispatch({ type: FETCH_CONTACTS_FAILURE, error: (error || err) });
  }
}

export const fetchContactsVerificados = (userName) => async (dispatch) => {
  dispatch({ type: FETCH_CONTACTS_REQUEST })
  try {
    const { data } = await Api.req.get(`/prospectos/staff`);
    const parsedData = await data.data;
    parsedData.sort((a, b) => a.lastInboundMsgTime > b.lastInboundMsgTime)

    if (parsedData) {
      dispatch({ type: FETCH_CONTACTS_SUCCESS, payload: parsedData })
      dispatch({ type: CHECK_UNREAD })
    }

  }
  catch (err) {
    let error = err.message;
    if (err.response) {
      error = err.response.data.error;
    } else if (err.request) {
      error = err.request;
    }
    dispatch({ type: FETCH_CONTACTS_FAILURE, error: (error || err) });
  }
}

export const fetchContacts = (userName) => async (dispatch) => {
  dispatch({ type: FETCH_CONTACTS_REQUEST })
  try {
    const { data } = await Api.req.get(`/prospectos/agent/${userName}/prospectos`);
    const parsedData = await data.data;
    parsedData.sort((a,b) => a.lastInboundMsgTime > b.lastInboundMsgTime) 
    
    if(parsedData){
      dispatch({ type: FETCH_CONTACTS_SUCCESS, payload: parsedData })
      dispatch({type : CHECK_UNREAD })
    }

  }
  catch (err) {
    let error = err.message;
    if (err.response) {
      error = err.response.data.error;
    } else if (err.request) {
      error = err.request;
    }
    dispatch({ type: FETCH_CONTACTS_FAILURE, error: (error || err) });
  }
}

export const fetchContactsFailure = error => {
  return {
    type: FETCH_CONTACTS_FAILURE,
    payload: error
  }
}

export const emptyContacts = () => (dispatch) => {

  dispatch({
    type: EMPTY_CONTACTS
  })
  dispatch({type: CHECK_UNREAD })
}

export const addMessage = (message) => (dispatch) => {
  dispatch({
    type: ADD_MESSAGE_SUCCESS,
    payload: message
  })
  dispatch({type: CHECK_UNREAD })
}

export const handleMessageDelivered = (message) => (dispatch) => {
  dispatch({
    type: SET_MESSAGE_DELIVERED,
    payload: message
  })
}

export const handleMessageRead = (message) => (dispatch) => {
  dispatch({
    type: SET_MESSAGE_READ,
    payload: message
  })
}

export const handleMessageRejected = (message) => (dispatch) => {
  dispatch({
    type: SET_MESSAGE_REJECTED,
    payload: message
  })
}

export const sendMessage = (message, utms) => async (dispatch) => {
  
  
  
  const regex = /\bhttps?:\/\/\S+/gi;
  let prev = message.message.msg;
  let str = message.message.msg;
  let m;
  let arrayLinks = [];

  while ((m = regex.exec(str)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
        regex.lastIndex++;
    }
    
    // The result can be accessed through the `m`-variable.
    m.forEach((match, groupIndex) => {
        arrayLinks.push(match)
        //console.log(`Found match, group ${groupIndex}: ${match}`);
    });
  }

  for(var h = 0; h < arrayLinks.length ; h ++ ){
    let str = arrayLinks[h];
    let tieneTvComprasLink = str.includes("https://tevecompras.com", 0) ||str.includes("https://www.tevecompras.com", 0) 
    //let tieneUtmCampaign = str.includes("utm_campaign", 0); 
    let tieneParametros = str.includes("?", 0) ? '&' : '?';  
    if(tieneTvComprasLink && utms){
      let parameters = arrayLinks[h] + tieneParametros + "utm_source="+ utms.utm_source +"&utm_campaign=" + utms.utm_campaign + "&utm_medium=" + utms.utm_medium ;
      message.message.msg = message.message.msg.replace(arrayLinks[h], parameters);
    }
  }
  
  let prevMsg = dispatch({ type: SEND_MESSAGE_REQUEST, payload: message})
  try{
    const { data } = await Api.req.post(`dispatcher/prospecto/${message.contactId}/dispatch`, message );
    //const { data } = await Api.req.post(`/prospectos/agent/LEANDRO/prospecto/${message.contactId}/reply`, message );
    // console.log('entro en la accion')
    dispatch({
      type: SEND_MESSAGE_SUCCESS,
      payload: { message: message, data : data }
    })
  }
  catch(err){
    console.log(err)
    dispatch(appActions.addToast('Ha habido un error al enviar el mensaje: ' + message.message.msg ,'error'))
    let error = err.message;
    if (err.response) {
      error = err.response.data.error;
    } else if (err.request) {
      error = err.request;
    }
    dispatch({ type: SEND_MESSAGE_FAILURE, error: (error || err) });
    
  }
}

export const markAllAsRead = (message) => async (dispatch) => {
  try{
    const { data } = await Api.req.put(`/prospectos/agent/LEANDRO/prospecto/${message.contactId}/read`, message );
    // console.log('entro en la accion')
    dispatch({
      type: ALL_MESSAGE_READ_SUCCESS,
      payload: message
    })
    dispatch({type: CHECK_UNREAD })
  }
  catch(err){
    let error = err.message;
    if (err.response) {
      error = err.response.data.error;
    } else if (err.request) {
      error = err.request;
    }
    dispatch({ type: ALL_MESSAGE_READ_FAILURE, error: (error || err) });
  }
}

export const incomingMessage = (message) => async (dispatch) => {
  try{
    // const { data } = await Api.req.post(`/prospectos/agent/LEANDRO/prospecto/${message.contactId}/reply`, message );
    dispatch(appActions.addSound('incomingMsg'))
    dispatch({
      type: INCOMING_MESSAGE_SUCCESS,
      payload: message
    })
    dispatch({type : CHECK_UNREAD })
  }
  catch(err){
    let error = err.message;
    if (err.response) {
      error = err.response.data.error;
    } else if (err.request) {
      error = err.request;
    }
    dispatch({ type: INCOMING_MESSAGE_FAILURE, error: (error || err) });
  }
}

export const dismissContact = (contact, userName) => async (dispatch) => {
  console.log(contact);
  try{
    const { data } = await Api.req.delete(`/prospectos/agent/${userName}/prospecto/${contact.id}`, { status: 99 });
    dispatch({ type: CONTACT_DIPOSITION_SUCCESS, payload: contact })
    dispatch({ type : CHECK_UNREAD })
  }
  catch(err){
    let error = err.message;
    if (err.response) {
      error = err.response.data.error;
    } else if (err.request) {
      error = err.request;
    }
    dispatch({ type: CONTACT_DIPOSITION_FAILURE, error: (error || err) });
  }
}

export const getMessageReference = (data, contact) => async (dispatch) => {

  var promises = [];
  var tienenMsgReference = data.messages.filter((item) => item.messagereference != null  )
  var messages = data.messages;

  for (var i = 0; i < tienenMsgReference.length ; i ++){
    promises.push(Api.req.get(`https://omni.tevecompras.com/media/instagram/${tienenMsgReference[i].messagereference}`))  
  }

  Promise.all(promises).then(
    (response) => {
      let arrayMsgReference =  response.map((item) => item.data );

      for (var i = 0; i < arrayMsgReference.length; i++) {
        let indexReplace =  messages.findIndex((message) => message.messagereference == arrayMsgReference[i].id  );
        messages[indexReplace].messageReferenceInfo = arrayMsgReference[i]
      }

      dispatch({
        type: FETCH_MESSAGES_SUCCESS,
        payload: data
      })
    }
  )

}

export const fetchMessages = (contact, userName, all) => async (dispatch) => {

  dispatch({type: FETCH_MESSAGES_REQUEST})
  try {

    let finUrl = all ? '?all=true' : '';

    const { data } = await Api.req.get(`/prospectos/agent/${userName}/prospecto/${contact.id}/${contact.campaignid}/interactions${finUrl}`, { status: 1 });

    //Instagram mete data de message reference
    if (contact.campaignid == 6){
      dispatch(getMessageReference(data, contact)) 
    }else{
      dispatch({
        type: FETCH_MESSAGES_SUCCESS,
        payload: data
      })
    }

    dispatch({type : CHECK_UNREAD })
  }
  catch(err) {
    dispatch({
      type: FETCH_MESSAGES_FAILURE,
      payload: err
    })    
  }
}

export const fetchClientData = (contact) => async (dispatch) => {
  dispatch({ type: FETCH_CLIENT_DATA_REQUEST })
  try {
    const { data } = await Api.req.get(`/prospectos/contact/${contact.id}/cliente`);
    dispatch({
      type: FETCH_CLIENT_DATA_SUCCESS,
      payload: { contactId: contact.id , data: data.data[0]  } 
    })
  }
  catch (err) {
    dispatch({
      type: FETCH_CLIENT_DATA_FAILURE,
      payload: err
    })
  }
}

export const fetchMessagesFiltered = (contact, userName) => async (dispatch) => {
  dispatch({type: FETCH_MESSAGES_REQUEST})
  try {
    const { data } = await Api.req.get(`/prospectos/agent/${userName}/prospecto/${contact.id}/${contact.campaignid}/interactions`, { status: 1 });
    dispatch({
      type: FETCH_MESSAGES_FILTERED_SUCCESS,
      payload: data
    })
    dispatch({type : CHECK_UNREAD })
  }
  catch(err) {
    dispatch({
      type: FETCH_MESSAGES_FILTERED_FAILURE,
      payload: err
    })    
  }
}

export const handleMessageReacted = (message) => (dispatch) => {
  dispatch({
    type: SET_MESSAGE_REACTED,
    payload: message
  })
}