import React,{ useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stats from './Stats';
import Pedidos from './Pedidos';
import Datos from './Datos';
import Cliente from './Cliente';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
    container: {
      height: '100%',
      'overflow' : 'hidden'
    },
    closeBtn : {
        width: '50px',
        marginTop: '20px',
        float: 'left',
        position: 'absolute'
    }

});

const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
        backgroundColor: '#1890ff',
    },
})(Tabs);

const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 100,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$selected': {
            color: '#1890ff',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#40a9ff',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

const StatsData = ( props ) => {
    const classes = useStyles();
    const [isExpanded, setExpanded] =  useState(false); 
    const [value, setValue] = useState(0);


    const handleExpandClick = () => {
        props.handleChangeMenuState(); 
        setExpanded(!isExpanded);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getPedidosTab = () => {
        if(!!props.contact.Nro_Docu && !!props.contact.Tipo_Docu){
            return <AntTab label="Pedidos"   />
        }else{
            return <AntTab label="Pedidos" disabled  />
        }
       
    }


    return (
            <div className={classes.container}>
                <div className={classes.closeBtn}>
                    <IconButton onClick={() => handleExpandClick() }>
                         <CloseIcon />
                    </IconButton>
                </div>
                
                
                <Avatar style={{borderRadius: 50, width: 150, height: 150, color: 'white', padding: '20', margin: '0 auto', marginTop: '20px'}} src={props.contact.profile_pic} ></Avatar>
                <br style={{ height: 20}}></br>
                <AntTabs 
                 value={value}
                 indicatorColor="primary"
                 textColor="primary"
                 variant="scrollable"
                 scrollButtons="auto"
                 aria-label="scrollable auto tabs example"
                 onChange={handleChange} 
                 >
                        <AntTab label="Datos"  />
                        <AntTab label="Cliente"  />
                        {getPedidosTab()}
                        <AntTab label="Stats"/>
                </AntTabs>
                <Box  style={{'height' : '100%',padding : '10px'}} >
                    { value == 3 ? <Stats contact={props.contact} /> : null }
                    { value == 0 ? <Datos handleGuardoDatos={props.handleGuardoDatos} contact={props.contact} ></Datos>  : null }
                    { value == 2 ? <Pedidos contact={props.contact}></Pedidos> : null }
                    {value == 1  ? <Cliente contact={props.contact}></Cliente> : null}   
                </Box>
                
            </div>
    )
};

export default React.memo(StatsData);