import React, { useEffect, useState, useRef } from "react";
import { makeStyles, ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { blue, lightGreen, green, indigo, pink, red } from '@material-ui/core/colors';
import { CssBaseline } from "@material-ui/core";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  HashRouter
} from 'react-router-dom';

import Header from '../Header';
import Menu from '../Menu';
import MenuRight from '../MenuRight';
import drawerWidth from './../../factories/DrawerWidth'

import Login from '../Login';
import ChatsDashboard from '../ChatsDashboard';
import AgentsDashboard from '../SupervisorDashboard/AgentsDashboard/AgentsDashboard.js';
import SupervisorDashboard from '../SupervisorDashboard/SupervisorDashboard/SupervisorDashboard';
import AdminDashboard from '../AdminDashboard';
import LoadingPage from './../Loading';

import ToastContainer from '../Toast/Container';
import Toast from '../Toast';
import AudioPlayer from '../AudioPlayer';

import AuthedRoute from './AuthedRoute';
import UnauthedRoute from './UnauthedRoute';

import { useOnMount } from '../../hooks';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as authActions from '../../state/auth/actions';

import * as appSelectors from '../../state/app/selectors';
import * as authSelectors from '../../state/auth/selectors';
import * as contactSelectors from '../../state/contacts/selectors';
import * as userSelectors from '../../state/users/selectors';

import Sockets from './Sockets';
import Firebase from '../../firebase';
import { SW } from '../../constants';
import { notificationSettings } from '../../localforage';
import * as appActions from '../../state/app/actions';
import lightTheme from "./themes/light-theme.js";
import darkTheme from "./themes/dark-theme.js";
import Marketing from "../Marketing/Marketing.js";
import Privacidad from './../Policy/Privacidad';
import Privacy from './../Policy/Privacy';


const FullApp = ( props ) => {
  return (
    <>
      <Header
        classes={props.classes}
      />  
      <Menu  />
      <main className={props.classes.content}>
        <div className={props.classes.toolbar} />
        {props.component}
      </main>
      <MenuRight  />
    </>
  )
}

const OtherApp = ( props ) => {
  return (
    <>
      <Header
        classes={props.classes}
      />  
      <Menu open={props.open} setOpen={props.setOpen} />
      <main className={props.classes.content}>
        <div className={props.classes.toolbar} />
          Este es otro menu
      </main>
    </>
  )
}

const MainApp = () => {

  
  function getFaviconEl() {
    return document.getElementById("favicon");
  }

  function whichFavicon() {
    if(!me){
      return 'favicon.ico';
    }
    if(estadoPausa){
      return 'assets/icons/yellowCircle.ico';
    }
    if(tieneUnread){
      return 'assets/icons/redCircle.ico';
    }else{
      return 'assets/icons/greenCircle.ico';
    }
    
  }

  function changeTitle () {
    if(tieneUnread && me){
      r.current  = r.current + 1;
      if (r.current % 2 === 0){
        document.title = "Mensajes sin leer";
      } else {
        document.title = "TeVeCompras Omnichannel";
      }
    }else{
      document.title = "TeVeCompras Omnichannel";
    }
  }

  const me = useSelector(authSelectors.getMe(), shallowEqual);
  const tieneUnread = useSelector(contactSelectors.hasMessagesUnread());
  const estadoPausa = useSelector(userSelectors.getEstadoPausa())
  let favicon = getFaviconEl(); // Accessing favicon element
  favicon.href = whichFavicon();
  const [counter, setCounter] = useState(0);
  const r = useRef(0);

  const dispatch = useDispatch();
 
  const isSuper = useSelector(authSelectors.isSuper(), shallowEqual);
  const isAdmin = useSelector(authSelectors.isAdmin(), shallowEqual);
  const toasts = useSelector(appSelectors.getToasts());
  const darkMode = useSelector(appSelectors.getEstadoDarkMode());
  const soundsToPlay = useSelector(appSelectors.getSoundsToPlay())
  
  // const isAutoLoggingIn = useSelector(authSelectors.isAutoLoggingIn());

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [initialized, setInitialized] = useState(false);
  const [askedForPushNotifications, setAskedForPushNotifications] = useState(false);
  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useOnMount(() => {
    const { jwt } = window.localStorage;
    if (jwt) {
      dispatch(authActions.jwtLogin(jwt));
    }

    setInitialized(true);
    dispatch(appActions.initUser())

  });  

  useEffect(() => {
   
    const interval = setInterval(() => {
      changeTitle();
    }, 5000);
    if(me == null){
      document.title = "TeVeCompras Omnichannel";
      clearInterval(interval)
    }
    return () => clearInterval(interval);
  },[tieneUnread,me])

  useEffect(() => {
    if (me) {
      // save data for service worker
      notificationSettings.setItem(SW.NEW_AUTH, me.username);
    }

    if (me && !askedForPushNotifications) {
      Firebase.getPermission()
        .then(() => {
          // console.log(me.username)
          // Firebase.onMessage(me.username);
          setAskedForPushNotifications(true);
        })
        // eslint-disable-next-line no-console
        .catch(e => console.error(e));
    }
  }, [me]);

  return (
    
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <LoadingPage />
      <div className={classes.root}>
        <CssBaseline />
  
        <HashRouter basename='/'>
          <Sockets />
            <Switch>
              <Route
                exact
                path="/"
                render={() => (
                  !me
                    ? <Login classes={classes} />
                    : <FullApp
                        classes={classes}
                        handleDrawerOpen={handleDrawerOpen}
                        handleDrawerClose={handleDrawerClose}
                        open={open}
                        component={<ChatsDashboard/>}
                      />
                )}
              />
              <Route
                exact
                path="/dashboard"
                render={() => (
                  !me
                    ? <Login classes={classes} />
                    : <FullApp classes={classes}
                      handleDrawerOpen={handleDrawerOpen}
                      handleDrawerClose={handleDrawerClose}
                      open={open}
                    />
                )}
              /> 
              <Route
                exact
                path="/privacy"
                render={() => (
                  <>
                    <Privacy />
                  </>
                )}
              /> 
              <Route
                exact
                path="/privacidad"
                render={() => (
                  <>
                    <Privacidad />
                  </>
                )}
              /> 
              <Route
                exact
                path="/todas"
                render={() => (
                  !me
                    ? <Login classes={classes} />
                    : <FullApp
                        classes={classes}
                        handleDrawerOpen={handleDrawerOpen}
                        handleDrawerClose={handleDrawerClose}
                        open={open}
                        component={<ChatsDashboard todas={true} />}
                      />
                )}
              />
              <Route
                exact
                path="/marketing"
                render={() => (
                  !me
                    ? <Login classes={classes} />
                    : <FullApp
                      classes={classes}
                      handleDrawerOpen={handleDrawerOpen}
                      handleDrawerClose={handleDrawerClose}
                      open={open}
                      component={<Marketing />}
                    />
                )}
              /> 
              <Route
                exact
                path="/verificados"
                render={() => (
                  !me
                    ? <Login classes={classes} />
                    : <FullApp
                      classes={classes}
                      handleDrawerOpen={handleDrawerOpen}
                      handleDrawerClose={handleDrawerClose}
                      open={open}
                      component={<ChatsDashboard todas={false} verificados={true} />}
                    />
                )}
              /> 
              <Route
                exact
                path="/mercadolibre"
                render={() => (
                  !me
                    ? <Login classes={classes} />
                    : <FullApp
                      classes={classes}
                      handleDrawerOpen={handleDrawerOpen}
                      handleDrawerClose={handleDrawerClose}
                      open={open}
                      component={<ChatsDashboard todas={false} mercadolibre={true} />}
                    />
                )}
              /> 
              <Route
                exact
                path="/supervisor"
                render={() => (
                  !me
                    ? <Login classes={classes} />
                    : isSuper && <FullApp
                      classes={classes}
                      handleDrawerOpen={handleDrawerOpen}
                      handleDrawerClose={handleDrawerClose}
                      open={open}
                      component={<ChatsDashboard super={true} />}
                    />
                )}
              />
            <Route
              exact
              path="/supervisorInbox"
              render={() => (
                !me
                  ? <Login classes={classes} />
                  : isSuper && <FullApp
                    classes={classes}
                    handleDrawerOpen={handleDrawerOpen}
                    handleDrawerClose={handleDrawerClose}
                    open={open}
                    component={<ChatsDashboard super={true} inbox={true} />}
                  />
              )}
            />
              <Route
                exact
                path="/supervisor/agents"
                render={() => (
                  !me ? <Login classes={classes} /> 
                  : isSuper && <FullApp
                      classes={classes}
                      handleDrawerOpen={handleDrawerOpen}
                      handleDrawerClose={handleDrawerClose}
                      open={open}
                      component={<AgentsDashboard classes={classes}
                        handleDrawerOpen={handleDrawerOpen}
                        handleDrawerClose={handleDrawerClose}
                        open={open}  
                      /> }
                  />
                )}
              />              
              <Route
                exact
                path="/supervisor/campaigns"
                render={() => (
                  !me ? <Login classes={classes} /> 
                  : isSuper && <FullApp
                      classes={classes}
                      handleDrawerOpen={handleDrawerOpen}
                      handleDrawerClose={handleDrawerClose}
                      open={open}
                      component={<SupervisorDashboard classes={classes}
                        handleDrawerOpen={handleDrawerOpen}
                        handleDrawerClose={handleDrawerClose}
                        open={open}  
                      /> }
                  />
                )}
              />
              <Route
                exact
                path="/admin"
                render={() => (
                  !me ? <Login classes={classes} /> 
                  : isAdmin && <FullApp classes={classes}
                        handleDrawerOpen={handleDrawerOpen}
                        handleDrawerClose={handleDrawerClose}
                        open={open}  
                        component={<AdminDashboard/>}
                  /> 
                )}
              />               
              <UnauthedRoute
                path="/login"
                component={Login}
              />
            </Switch>
          </HashRouter>

          <ToastContainer>
            {toasts.map((content, index) => (
              <Toast type={content.type} index={index} id={content.id} key={content.id}>{content.toast}</Toast>
            ))}
          </ToastContainer>

            {soundsToPlay.map((content, index) => (
              <AudioPlayer type={content.type} index={index} id={content.id} key={content.id}/>
            ))}
      </div>
    </ThemeProvider>

  )

};

export default MainApp;


const useStyles = makeStyles(theme => ({
  hide: {
    display: 'none',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1
  },  
  menu: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#676767",
  }, 
  avatar: {
    margin: 0,
  },
  menuAvatar: {
    margin: 5
  },  
  root: {
    display: 'flex'
  },
  card: {
    display: "flex"
  },
  rightBorder: {
    borderRight: "solid #d0D0D0 1px"
  },
  background: {
    position: "absolute",
    height: 200,
    width: "100%",
    top: 0,
    background: "#64af00"
  },
  backgroundContainer: {
    background: "url(./assets/background.png) center center #e5ddd5",
    backgroundRepeat: "repeat-x",
    position: 'fixed',
    height: '100%',
    width: '100%',
    opacity: 0.1,
  },
  paper: {
    background: "#9de1fe",
    padding: 20
  },
  information: {
    color: "#444"
  },
  WhatsAppIcon: {
    color: '#25D366',
    backgroundColor: 'rgba(230,220,210,0)'
  },
  FacebookIcon: {
    color: '#fff',
    backgroundColor: 'rgba(230,220,210,0)'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  sectionDesktop: {
    // display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    // padding: 10,
    // marginLeft: 10,
    // marginRight: 10,
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },  
  RichEditor: {
    border: '1px solid #ffcc00',
    cursor: 'text',
    fontSize: '16px',
    marginTop: '10px',
    height: '100px',
    color: '#000'
  },
  modalF: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperF: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    color: '#000'
  }

}));

